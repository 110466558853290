import { FC, PropsWithChildren, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";

const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [client] = useState(() => new QueryClient());

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default QueryProvider;
