import { FC } from "react";
import Portal from "src/components/Portal";
import { TNotification } from "src/types/contexts/NotificationContext";
import styles from "./styles.module.css";
import Notification from "src/components/Notification";

type TNotificationsPortalProps = {
  notifications: TNotification[];
};

const NotificationsPortal: FC<TNotificationsPortalProps> = ({
  notifications,
}) => {
  return (
    <Portal>
      <div className={styles.notifications__wrapper}>
        {notifications.map((n) => (
          <Notification key={n.id} {...n} />
        ))}
      </div>
    </Portal>
  );
};

export default NotificationsPortal;
