import { FC } from "react";
import Greeting from "./components/Greeting";
import About from "./components/About";
import Assortment from "./components/Assortment";
import Conclusion from "./components/Conclusion";
import Location from "./components/Location";

const Home: FC = () => {
  return (
    <>
      <Greeting />
      <About />
      <Assortment />
      <Location />
      <Conclusion />
    </>
  );
};

export default Home;
