import { useQuery } from "react-query";
import { fetchProducts } from "../handlers/fetch-products";

export const QUERY_KEY = "useFetchProducts";

export const useFetchProducts = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchProducts,
  });
};
