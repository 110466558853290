import { FC, ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import styles from "./styles.module.css";

type TInputProps = {
  label: string;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input: FC<TInputProps> = forwardRef(
  ({ label, error, ...rest }, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <div className={styles.input__wrapper}>
        <label>{label}</label>
        <input
          {...rest}
          className={`${styles.input} ${rest.className}`}
          ref={ref}
          aria-invalid={!!error}
        />
        {error && <span className={styles.error__msg}>{error}</span>}
      </div>
    );
  }
);

export default Input;
