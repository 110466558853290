import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from "react";
import NotificationsPortal from "src/templates/NotificationsPortal";
import {
  TNotification,
  TNotificationContext,
  TNotifyProps,
} from "src/types/contexts/NotificationContext";
import * as uuid from "uuid";

export const NotificationContext = createContext({} as TNotificationContext);

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<TNotification[]>([]);

  const closeNotification = useCallback((id: string) => {
    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 3000);
  }, []);

  const notify = (props: TNotifyProps) => {
    const n = {
      id: uuid.v4(),
      ...props,
    };

    setNotifications([...notifications, n]);

    closeNotification(n.id);
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <NotificationsPortal notifications={notifications} />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
