import React, { FC } from "react";

const Minus: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.6875 7C12.6875 7.48398 12.2965 7.875 11.8125 7.875H2.1875C1.70352 7.875 1.3125 7.48398 1.3125 7C1.3125 6.51602 1.70352 6.125 2.1875 6.125H11.8125C12.2965 6.125 12.6875 6.51602 12.6875 7Z" />
    </svg>
  );
};

export default Minus;
