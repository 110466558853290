import { useMutation } from "react-query";
import { createOrder } from "../handlers/create-order";
import { TApiProps } from "src/types/api";

export const MUTATION_KEY = "useCreateOrder";

export const useCreateOrder = (props?: TApiProps) => {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: createOrder,
    onError: () => {
      props?.onError?.();
    },
    onSuccess: () => {
      props?.onSuccess?.();
    },
  });
};
