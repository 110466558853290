import { FC, ImgHTMLAttributes } from "react";
import { useLazyImage } from "./hooks/useLazyImage";
import Loader from "../Loader";

type TImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  alt: string;
  src: string;
};

const LazyImage: FC<TImageProps> = (props) => {
  const { isError, isLoading, url } = useLazyImage(props.src);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return null;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} src={url} />;
};

export default LazyImage;
