import { FC } from "react";

const Plus: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.875 2.1875C7.875 1.70352 7.48398 1.3125 7 1.3125C6.51602 1.3125 6.125 1.70352 6.125 2.1875V6.125H2.1875C1.70352 6.125 1.3125 6.51602 1.3125 7C1.3125 7.48398 1.70352 7.875 2.1875 7.875H6.125V11.8125C6.125 12.2965 6.51602 12.6875 7 12.6875C7.48398 12.6875 7.875 12.2965 7.875 11.8125V7.875H11.8125C12.2965 7.875 12.6875 7.48398 12.6875 7C12.6875 6.51602 12.2965 6.125 11.8125 6.125H7.875V2.1875Z" />
    </svg>
  );
};

export default Plus;
