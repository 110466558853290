import { FC } from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./constants/routes";
import Footer from "./components/Footer";
import ProductsContext from "./contexts/ProductsContext";
import QueryProvider from "src/providers/QueryProvider";
import NotificationProvider from "./contexts/NotificationContext";

export const App: FC = () => {
  return (
    <NotificationProvider>
      <QueryProvider>
        <BrowserRouter>
          <ProductsContext>
            <Header />
            <Routes>
              <Route path={routes.HOME} element={<Home />} />
            </Routes>
          </ProductsContext>
          <Footer />
        </BrowserRouter>
      </QueryProvider>
    </NotificationProvider>
  );
};

export default App;
