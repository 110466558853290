import { FC } from "react";
import styles from "./styles.module.css";
import Image from "../Image";

const Footer: FC = () => {
  return (
    <div className={styles.footer__wrapper}>
      <div className={styles.footer__container}>
        <Image src="assets/logo.png" alt="Logo" />
        <span>aquatoria.zp.ua@gmail.com</span>
        <div className={styles.phone_numbers__wrapper}>
          <span>Num 1</span>
          <span>Num 2</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
