import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import Loader from "../Loader";

type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    isLoading?: boolean;
  };

const Button: FC<TButtonProps> = ({ children, isLoading, ...rest }) => {
  return (
    <button {...rest} className={`${styles.btn} ${rest.className}`}>
      {!isLoading ? children : <Loader variant="light" />}
    </button>
  );
};

export default Button;
