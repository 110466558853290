import { FC } from "react";
import Image from "../Image";
import styles from "./styles.module.css";
import { navLinks } from "src/constants/header";
import Cart from "src/icons/Cart";
import { routes } from "src/constants/routes";
import { useProductsContext } from "src/contexts/ProductsContext/hooks";

const Header: FC = () => {
  const { onOpen } = useProductsContext();

  return (
    <header className={styles.header__wrapper}>
      <div className={styles.header__container}>
        <a href={routes.HOME}>
          <Image src="assets/logo.png" alt="Aquatoria" />
        </a>
        <nav className={styles.nav__wrapper}>
          <ul className={styles.nav__list}>
            {Object.values(navLinks).map((l) => (
              <li key={l.id}>
                <a href={"#" + l.id}>{l.label}</a>
              </li>
            ))}
          </ul>
        </nav>
        <button className={styles.cart__btn} onClick={onOpen}>
          <Cart />
        </button>
      </div>
    </header>
  );
};

export default Header;
