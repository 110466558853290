export const aboutList = [
  {
    title: "Кришталево чиста вода",
    text: "Ми отримуємо найкращу воду, щоб кожен ковток відчув смак чистоти.",
  },
  {
    title: "Зручна доставка",
    text: "Насолоджуйтеся розкішшю доставки освіжаючої води прямо до дверей за розкладом.",
  },
  {
    title: "Екологічно чистi",
    text: "Ми прагнемо до сталого розвитку, пропонуючи екологічно чисте пакування та рішення для доставки.",
  },
];
