import { FC } from "react";
import Modal from "src/components/Modal";
import { ICartProduct } from "src/interfaces/product";
import styles from "./styles.module.css";
import Trash from "src/icons/Trash";
import Minus from "src/icons/Minus";
import Plus from "src/icons/Plus";
import Input from "src/components/Input";
import { useForm } from "react-hook-form";
import { IUser } from "src/interfaces/user";
import Button from "src/components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { orderValidation } from "src/validations/order";
import { useCreateOrder } from "src/api/hooks/useCreateOrder";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { useNotificationContext } from "src/contexts/NotificationContext/hooks";

type TCartModal = {
  products: ICartProduct[];
  open: boolean;
  onClose: () => void;
  removeProduct: (id: string) => void;
  quantityHandler: (id: string, q: number) => void;
  clearProducts: () => void;
};

const CartModal: FC<TCartModal> = ({
  products,
  onClose: closeModal,
  open,
  removeProduct,
  quantityHandler,
  clearProducts,
}) => {
  const { notify } = useNotificationContext();

  const [user, setUser] = useLocalStorage<IUser | null>("user", null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IUser>({
    resolver: yupResolver(orderValidation),
    values: {
      address: user?.address ?? "",
      name: user?.name ?? "",
      phone: user?.phone ?? "",
    },
  });

  const { isLoading, mutate } = useCreateOrder({
    onSuccess: () => {
      clearProducts();
      onClose();
      notify({
        type: "info",
        message: "Замовлення успішно створено",
      });
    },
    onError: () => {
      onClose();
      clearProducts();
      notify({
        type: "error",
        message: "Щось пішло не так!",
      });
    },
  });

  const total = products.reduce((acc, p) => (acc += p.price * p.quantity), 0);

  const onSubmit = (data: IUser) => {
    if (!products.length) {
      return;
    }

    mutate({
      address: data.address,
      name: data.name,
      phone: data.phone,
      products: products.map((p) => ({ id: p.id, quantity: p.quantity })),
    });
  };

  const onClose = () => {
    closeModal();

    const name = watch("name");
    const address = watch("address");
    const phone = watch("phone");

    setUser({
      name,
      address,
      phone,
    });
  };

  return (
    <Modal onClose={onClose} open={open} title="Кошик">
      {products.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.user_data__wrapper}>
            <Input
              {...register("phone")}
              label="Номер"
              error={errors.phone?.message}
            />
            <Input
              {...register("name")}
              label="Ім'я"
              error={errors.name?.message}
            />
            <Input
              {...register("address")}
              label="Адреса"
              error={errors.address?.message}
            />
          </div>
          <ul className={styles.products__list}>
            {products.map((p) => (
              <li key={p.id} className={styles.product__item}>
                <h6>{p.name}</h6>
                <span className={styles.product_price}>{p.price}грн</span>
                <div className={styles.quantity_controller__wrapper}>
                  <button
                    onClick={() => quantityHandler(p.id, p.quantity - 1)}
                    type="button"
                  >
                    <Minus />
                  </button>
                  <h6>{p.quantity}</h6>
                  <button
                    onClick={() => quantityHandler(p.id, p.quantity + 1)}
                    type="button"
                  >
                    <Plus />
                  </button>
                </div>
                <button
                  className={styles.remove__btn}
                  onClick={() => removeProduct(p.id)}
                  type="button"
                >
                  <Trash />
                </button>
              </li>
            ))}
          </ul>
          <div className={styles.modal__footer}>
            <span>До сплати: {total}грн</span>
            <Button
              disabled={!total || !products.length || isLoading}
              isLoading={isLoading}
            >
              Замовити
            </Button>
          </div>
        </form>
      ) : (
        <span className={styles.empty_state__text}>Наразі кошик порожній</span>
      )}
    </Modal>
  );
};

export default CartModal;
