import { FC, ImgHTMLAttributes } from "react";

type TImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  alt: string;
  src: string;
};

const Image: FC<TImageProps> = (props) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} />;
};

export default Image;
