import * as yup from "yup";

export const orderValidation = yup.object().shape({
  address: yup.string().required("Обов'язкове поле"),
  phone: yup
    .string()
    .test("phone", "Невалідний номер телефону. Приклад: 0671234567", (value) =>
      RegExp(/^\d+$/gm).test(value ?? "")
    )
    .length(10, "Невалідний номер телефону. Приклад: 0671234567")
    .required("Обов'язкове поле"),
  name: yup.string().required("Обов'язкове поле"),
});
