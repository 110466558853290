import { FC } from "react";
import styles from "./styles.module.css";
import Image from "src/components/Image";

const Conclusion: FC = () => {
  return (
    <div className={styles.conclusion__wrapper}>
      <div className={styles.conclusion__container}>
        <h2>
          “Відзначайте чисте зволоження з <span>Aquatoria</span>!”
        </h2>
        <h5>Обирайте зручність, обирайте якість, обирайте екологічність.</h5>
        <Image src="assets/delivery-boy-2.png" alt="Delivery boy" />
      </div>
    </div>
  );
};

export default Conclusion;
