import { FC, PropsWithChildren, useEffect } from "react";
import styles from "./styles.module.css";
import Close from "src/icons/Close";
import Portal from "../Portal";

type TModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
} & PropsWithChildren;

const Modal: FC<TModalProps> = ({ onClose, open, children, title }) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.modal__wrapper}>
        <div className={styles.modal__container}>
          <div className={styles.modal__heading}>
            <h5>{title}</h5>
            <button onClick={onClose} className={styles.close__btn}>
              <Close />
            </button>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
