import { FC } from "react";
import styles from "./styles.module.css";
import { navLinks } from "src/constants/header";
import { useProductsContext } from "src/contexts/ProductsContext/hooks";
import { useFetchProducts } from "src/api/hooks/useFetchProducts";
import Loader from "src/components/Loader";
import LazyImage from "src/components/LazyImage";

const Assortment: FC = () => {
  const { addProduct } = useProductsContext();

  const { data: products, isLoading } = useFetchProducts();

  return (
    <div className={styles.assrotment__wrapper} id={navLinks.assortment.id}>
      <div className={styles.assortment__container}>
        <h2 className={styles.title}>Асортимент</h2>
        {isLoading && <Loader size="lg" />}
        {products?.length && (
          <ul className={styles.list__wrapper}>
            {products.map((p) => (
              <li key={p.name} className={styles.product__card}>
                <div className={styles.product__img__wrapper}>
                  <LazyImage
                    alt="Product"
                    src={process.env.REACT_APP_STATIC_URL + "/" + p.img}
                  />
                </div>
                <div className={styles.product__container}>
                  <h6>{p.name}</h6>
                  <ul className={styles.product_options}>
                    {p.options.map((op) => (
                      <li key={op}>{op}</li>
                    ))}
                  </ul>
                  <div className={styles.product__footer}>
                    <span>{p.price}грн</span>
                    <button onClick={() => addProduct({ ...p, quantity: 1 })}>
                      Замовити
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Assortment;
