import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { ICartProduct } from "src/interfaces/product";
import CartModal from "src/templates/CartModal";

type TProductsContext = {
  addProduct: (p: ICartProduct) => void;
  removeProduct: (id: string) => void;
  onOpen: () => void;
};

export const ProductsContext = createContext({} as TProductsContext);

const ProductsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useLocalStorage<ICartProduct[]>("cart", []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const addProduct = (product: ICartProduct) => {
    onOpen();

    const pIdx = products.findIndex((p) => p.id === product.id);

    if (pIdx === -1) {
      return setProducts([...products, product]);
    }
  };

  const removeProduct = (id: string) => {
    setProducts(() => products.filter((p) => p.id !== id));
  };

  const quantityHandler = (id: string, q: number) => {
    if (q < 0) {
      return;
    }

    const pIdx = products.findIndex((p) => p.id === id);

    if (pIdx === -1) {
      return;
    }

    products[pIdx].quantity = q;

    setProducts(products);
  };

  const clearProducts = () => {
    setProducts([]);
  };

  return (
    <ProductsContext.Provider
      value={{
        addProduct,
        onOpen,
        removeProduct,
      }}
    >
      {children}
      <CartModal
        onClose={onClose}
        open={open}
        products={products}
        removeProduct={removeProduct}
        quantityHandler={quantityHandler}
        clearProducts={clearProducts}
      />
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
