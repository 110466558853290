import { useQuery } from "react-query";
import { fetchImage } from "../handlers/fetch-image";
import { TApiProps } from "src/types/api";
import { TFetchImageProps } from "src/types/api/image";

export const QUERY_KEY = "useFetchImage";

export const useFetchImage = (props: TApiProps<TFetchImageProps, string>) => {
  return useQuery({
    queryFn: () => fetchImage(props.url),
    queryKey: [QUERY_KEY, props.url],
    onSuccess: (data) => {
      props?.onSuccess?.(data);
    },
  });
};
