import React, { FC } from "react";
import styles from "./styles.module.css";

type TLoaderProps = {
  variant?: "light" | "primary";
  size?: "sm" | "lg";
};

const Loader: FC<TLoaderProps> = ({ variant = "primary", size = "sm" }) => {
  return (
    <div className={`${styles.loader} ${styles[variant]} ${styles[size]}`} />
  );
};

export default Loader;
