import { FC } from "react";
import styles from "./styles.module.css";
import Image from "src/components/Image";
import { navLinks } from "src/constants/header";
import ChevronRight from "src/icons/ChevronRight";

const Greeting: FC = () => {
  return (
    <div className={styles.greeting__wrapper}>
      <div className={styles.greeting__container}>
        <div className={styles.left_side}>
          <h1 className={styles.title}>
            Ласкаво просимо <br /> до <span>Aquatoria</span>
          </h1>
          <p>
            Втамуйте спрагу найчистішою та найсвіжішою водою прямо у вас на
            порозі. Наше прагнення до якості та зручності гарантує, що ви завжди
            будете насолоджуватися безпроблемним досвідом.
          </p>
          <a href={"#" + navLinks.assortment.id} className={styles.start__link}>
            <span>Почати</span> <ChevronRight />
          </a>
        </div>
        <div className={styles.right_side}>
          <Image src="assets/delivery-boy.png" alt="Delivery boy" />
        </div>
      </div>
    </div>
  );
};

export default Greeting;
