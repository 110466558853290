import { useState } from "react";
import { useFetchImage } from "src/api/hooks/useFetchImage";

export const useLazyImage = (url: string) => {
  const [img, setImg] = useState<string>();

  const { isLoading, isError } = useFetchImage({
    url,
    onSuccess: (url) => setImg(url),
  });

  return {
    isLoading,
    isError,
    url: img,
  };
};
