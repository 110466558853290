import { FC } from "react";

const Close: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.9844 6.41241L17.5876 5.01562L12 10.6032L6.41241 5.01562L5.01562 6.41241L10.6032 12L5.01562 17.5876L6.41241 18.9844L12 13.3968L17.5876 18.9844L18.9844 17.5876L13.3968 12L18.9844 6.41241Z" />
    </svg>
  );
};

export default Close;
