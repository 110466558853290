export const navLinks = {
  about: {
    id: "about",
    label: "Про нас",
  },
  assortment: {
    id: "assortment",
    label: "Асортимент",
  },
};
