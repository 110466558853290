import { FC } from "react";
import styles from "./styles.module.css";
import { aboutList } from "src/constants/home-page";
import { navLinks } from "src/constants/header";

const About: FC = () => {
  return (
    <div className={styles.about__wrapper} id={navLinks.about.id}>
      <h3 className={styles.title}>
        Чому варто вибрати <span>Aquatoria</span> для доставки води?
      </h3>
      <ul className={styles.list__wrapper}>
        {aboutList.map((i, idx) => (
          <li key={"about-list-item-" + idx}>
            {idx !== 0 && <div className={styles.line} />}
            <div className={styles.step}>{idx + 1}</div>
            <div className={styles.line} />
            <h3>{i.title}</h3>
            <p>{i.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default About;
