import { FC } from "react";
import { TNotification } from "src/types/contexts/NotificationContext";
import styles from "./styles.module.css";

type TNotificationProps = TNotification;

const Notification: FC<TNotificationProps> = ({ id, message, type }) => {
  const getTitle = (type: TNotification["type"]) => {
    switch (type) {
      case "info":
        return "Повідомлення";
      case "error":
        return "Помикла";
    }
  };

  return (
    <div className={`${styles.notification__wrapper} ${styles[type]}`}>
      <span>{getTitle(type)}</span>
      <span>{message}</span>
    </div>
  );
};

export default Notification;
