import { FC } from "react";
import Image from "src/components/Image";
import styles from "./styles.module.css";
import Button from "src/components/Button";
import { useNotificationContext } from "src/contexts/NotificationContext/hooks";

const Location: FC = () => {
  const { notify } = useNotificationContext();

  const fallbackClipboard = () => {
    notify({
      message: "Неможливо скопіювати геолокацію",
      type: "error",
    });
  };

  const copyToClipboard = async () => {
    if (!navigator.clipboard) {
      fallbackClipboard();
    }

    try {
      await navigator.clipboard.writeText(`47°50'11.2"N 35°01'05.2"E`);

      notify({
        message: "Скопійовано",
        type: "info",
      });
    } catch {
      fallbackClipboard();
    }
  };

  return (
    <div className={styles.location__wrapper}>
      <div className={styles.location__container}>
        <h2>Наша геолокація</h2>
        <div className={styles.img__wrapper}>
          <Image src="assets/map.png" alt="Map" />
        </div>
        <h6>Адреса: вулиця Дорошенка, 8, Запоріжжя</h6>
        <Button onClick={copyToClipboard}>Копіювати геолокацію</Button>
      </div>
    </div>
  );
};

export default Location;
